import axios from "axios";
import { User } from "../shared/user/user";
import { Game } from "./types/game";

export async function AddGameToDB(
  user: User,
  game: Game
): Promise<{
  api_result_status: {};
  api_result_data: Game[];
}> {
  const gameValues: any = game;
  delete gameValues._id;
  delete gameValues.created_at;

  gameValues.date_played = game.date_played.toLocaleString();
  const data = {
    type: "Insert",
    tableName: "game",
    app_id: user._id,
    values: gameValues,
  };

  try {
    let theResponse = await axios.post("/sql", data);
    return theResponse.data;
  } catch (e) {
    console.log("Login error", JSON.stringify(e), e.response.data);
    return e;
  }
}
