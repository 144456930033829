import axios from "axios";

export async function SendSignup(
  email: string,
  username: string,
  password: string
) {
  const data = {
    values: { email: email, password: password, username: username },
  };
  try {
    let theResponse = await axios.post("/auth/signUp", data);
    return theResponse.data;
  } catch (e) {
    console.log(JSON.parse(JSON.stringify(e)));
    console.log(e.response);
    return e;
  }
}
