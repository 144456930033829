/** @jsxImportSource @emotion/react */
import React from "react";
import dayjs from "dayjs";

import { Fragment } from "react";
import { useFormik } from "formik";
import { css } from "@emotion/core";
import { palette } from "../css/css";
import { UserInterface } from "../user/user";

export const GameEntryForm = (props: { user: UserInterface; path: string }) => {
  const formik = useFormik({
    initialValues: {
      date: dayjs().format("YYYY-MM-DDTHH:mm"),
      color: "white",
      result: 0,
      how_resolved: "checkmate",
      environment: "online",
      my_rating: 0,
      opponent_rating: 0,
      time_control: "60|10",
      site: "chess.com",
      pgn: "",
      my_opening: "",
      opponent_opening: "",
      played_opening_prep_correctly: false,
      move_number_when_out_of_prep: 0,
      misses: "",
      number_of_misses: 0,
      number_of_blunders: 0,
      notes: "",
      link_to_game: "",
      picked: "",
    },

    onSubmit: async (values) => {
      console.log(values, new Date(values.date));
      await props.user.handleAddGame(props.user, {
        _id: "",
        date_played: new Date(values.date),
        time_played: dayjs(values.date).format("HH:mm"),
        link_to_game: values.link_to_game,
        created_at: new Date(),
        environment: values.environment,
        round: 1,
        my_rating: values.my_rating,
        opponent_rating: values.opponent_rating,
        site: "",
        time_control: values.time_control,
        pgn: values.pgn,
        color: values.color,
        result: values.result,
        how_resolved: values.how_resolved,
        my_opening: values.my_opening,
        opponent_opening: values.opponent_opening,
        played_opening_prep_correctly: values.played_opening_prep_correctly,
        move_number_when_out_of_prep: values.move_number_when_out_of_prep,
        notes: values.notes,
        tournament: false,
        username: "",

        swindle: false,
        questions: "",
        thematic_moves: "",
        blunders: "",
        opponent_blunders: "",
        winning_strategy: "",
        indicated_practice: "",
        accuracy: 0,
        number_of_misses: values.number_of_misses,
        number_of_blunders: values.number_of_blunders,
        misses: values.misses,
        multi_move_misses: "",
      });
    },
  });

  return (
    <Fragment>
      <div data-testid={"GameEntryComponent"} css={topContainerCSS}>
        <form onSubmit={formik.handleSubmit} css={formCss}>
          <div css={rowCSS}>
            <div>
              <label css={inputLabelCss} htmlFor="date">
                Date
              </label>
              <input
                id="date"
                type="datetime-local"
                {...formik.getFieldProps("date")}
                css={inputCss}
              />
            </div>
            <div>
              <label css={inputLabelCss} htmlFor="number_of_misses">
                Number of Misses
              </label>
              <input
                id="number_of_misses"
                type="Number"
                {...formik.getFieldProps("number_of_misses")}
                css={inputCss}
              />
            </div>
          </div>
          <div css={rowCSS}>
            <div>
              {" "}
              <label css={inputLabelCss} htmlFor="number_of_blunders">
                Colored Played
              </label>
              <fieldset css={inputCss}>
                <div>
                  <input
                    type="radio"
                    id="white_color"
                    name="color"
                    value="white"
                    onChange={() => formik.setFieldValue("color", "white")}
                    checked={formik.values.color === "white"}
                  />
                  <label htmlFor="white_color">White</label>
                </div>

                <div>
                  <input
                    type="radio"
                    id="black_color"
                    name="drone"
                    value="black"
                    onChange={() => formik.setFieldValue("color", "black")}
                    checked={formik.values.color === "black"}
                  />
                  <label htmlFor="black_color">Black</label>
                </div>
              </fieldset>
            </div>
            <div>
              {" "}
              <label css={inputLabelCss} htmlFor="number_of_blunders">
                Number of Blunders
              </label>
              <input
                id="number_of_blunders"
                type="Number"
                {...formik.getFieldProps("number_of_blunders")}
                css={inputCss}
              />
            </div>
          </div>
          <div css={rowCSS}>
            <div>
              <label css={inputLabelCss} htmlFor="time_control">
                Time Control
              </label>
              <input
                id="time_control"
                type="String"
                {...formik.getFieldProps("time_control")}
                css={inputCss}
              />
            </div>
            <div>
              <label css={inputLabelCss} htmlFor="my_rating">
                My Rating
              </label>
              <input
                id="my_rating"
                type="numeric"
                {...formik.getFieldProps("my_rating")}
                css={inputCss}
              />
            </div>
          </div>
          <div css={rowCSS}>
            <div>
              <label css={inputLabelCss}>Environment</label>
              <fieldset css={inputCss}>
                <div>
                  <input
                    type="radio"
                    id="Online"
                    name="environment"
                    value={"online"}
                    onChange={() =>
                      formik.setFieldValue("environment", "online")
                    }
                    checked={formik.values.environment === "online"}
                  />
                  <label htmlFor="online">Online</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="OTB"
                    name="environment"
                    value={"OTB"}
                    onChange={() => formik.setFieldValue("environment", "OTB")}
                    checked={formik.values.environment === "OTB"}
                  />
                  <label htmlFor="online">OTB</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="onlineAndOTB"
                    name="environment"
                    value={"onlineWithBoard"}
                    onChange={() =>
                      formik.setFieldValue("environment", "onlineWithBoard")
                    }
                    checked={formik.values.environment === "onlineWithBoard"}
                  />
                  <label htmlFor="onlineAndOTB">Online With Board</label>
                </div>
              </fieldset>
            </div>
            <div>
              <label css={inputLabelCss} htmlFor="opponent_rating">
                Opponent Rating
              </label>
              <input
                id="opponent_rating"
                type="numeric"
                {...formik.getFieldProps("opponent_rating")}
                css={inputCss}
              />
            </div>
          </div>
          <div css={rowCSS}>
            <div>
              <label css={inputLabelCss}>Result</label>
              <fieldset css={inputCss}>
                <div>
                  <input
                    type="radio"
                    id="win"
                    name="result"
                    value={1}
                    onChange={() => formik.setFieldValue("result", 1)}
                    checked={formik.values.result === 1}
                  />
                  <label htmlFor="win">Win</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="loss"
                    name="result"
                    value={0}
                    onChange={() => formik.setFieldValue("result", 0)}
                    checked={formik.values.result === 0}
                  />
                  <label htmlFor="loss">Loss</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="draw"
                    name="result"
                    value={0.5}
                    onChange={() => formik.setFieldValue("result", 0.5)}
                    checked={formik.values.result === 0.5}
                  />
                  <label htmlFor="draw">Draw</label>
                </div>
              </fieldset>
            </div>
            <div>
              {" "}
              <label css={inputLabelCss} htmlFor="my_opening">
                My Opening
              </label>
              <input
                id="my_opening"
                type="String"
                {...formik.getFieldProps("my_opening")}
                css={inputCss}
              />
              <label css={inputLabelCss} htmlFor="opponent_opening">
                Opponent Opening
              </label>
              <input
                id="opponent_opening"
                type="String"
                {...formik.getFieldProps("opponent_opening")}
                css={inputCss}
              />
            </div>
          </div>
          <div css={rowCSS}>
            <div>
              <label css={inputLabelCss}>How Resolved</label>
              <fieldset css={inputCss}>
                <div>
                  <input
                    type="radio"
                    id="checkmate"
                    name="how_resolved"
                    value={"checkmate"}
                    onChange={() =>
                      formik.setFieldValue("how_resolved", "checkmate")
                    }
                    checked={formik.values.how_resolved === "checkmate"}
                  />
                  <label htmlFor="checkmate">Checkmate</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="resigned"
                    name="how_resolved"
                    value={"resigned"}
                    onChange={() =>
                      formik.setFieldValue("how_resolved", "resigned")
                    }
                    checked={formik.values.how_resolved === "resigned"}
                  />
                  <label htmlFor="resigned">Resigned</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="flagged"
                    name="how_resolved"
                    value={"flagged"}
                    onChange={() =>
                      formik.setFieldValue("how_resolved", "flagged")
                    }
                    checked={formik.values.how_resolved === "flagged"}
                  />
                  <label htmlFor="flagged">Flagged</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="abandon"
                    name="abandon"
                    value={"abandon"}
                    onChange={() =>
                      formik.setFieldValue("how_resolved", "abandon")
                    }
                    checked={formik.values.how_resolved === "abandon"}
                  />
                  <label htmlFor="abandon">Abandon</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="stalemate"
                    name="how_resolved"
                    value={"stalemate"}
                    onChange={() =>
                      formik.setFieldValue("how_resolved", "stalemate")
                    }
                    checked={formik.values.how_resolved === "stalemate"}
                  />
                  <label htmlFor="stalemate">Stalemate</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="agreed_draw"
                    name="agreed_draw"
                    value={"agreed_draw"}
                    onChange={() =>
                      formik.setFieldValue("how_resolved", "agreed_draw")
                    }
                    checked={formik.values.how_resolved === "agreed_draw"}
                  />
                  <label htmlFor="agreed_draw">Agreed Draw</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="repetition"
                    name="how_resolved"
                    value={"repetition"}
                    onChange={() =>
                      formik.setFieldValue("how_resolved", "repetition")
                    }
                    checked={formik.values.how_resolved === "repetition"}
                  />
                  <label htmlFor="repetition">Repetition</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="fifty_move"
                    name="how_resolved"
                    value={"fifty_move"}
                    onChange={() =>
                      formik.setFieldValue("how_resolved", "fifty_move")
                    }
                    checked={formik.values.how_resolved === "fifty_move"}
                  />
                  <label htmlFor="fifty_move">Fifty Move</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="insufficient_material"
                    name="how_resolved"
                    value={"insufficient_material"}
                    onChange={() =>
                      formik.setFieldValue(
                        "how_resolved",
                        "insufficient_material"
                      )
                    }
                    checked={
                      formik.values.how_resolved === "insufficient_material"
                    }
                  />
                  <label htmlFor="insufficient_material">
                    Insufficient Material
                  </label>
                </div>
              </fieldset>
            </div>
            <div>
              <label css={checkBoxCss}>Played Opening Correctly</label>
              <input
                id="played_opening_prep_correctly"
                type="checkbox"
                {...formik.getFieldProps("played_opening_prep_correctly")}
              />

              <label css={inputLabelCss} htmlFor="move_number_when_out_of_prep">
                Move Number When Out Of Prep
              </label>
              <input
                id="move_number_when_out_of_prep"
                type="Number"
                {...formik.getFieldProps("move_number_when_out_of_prep")}
                css={inputCss}
              />

              <label css={inputLabelCss} htmlFor="site">
                Site
              </label>
              <input
                id="site"
                type="String"
                {...formik.getFieldProps("site")}
                css={inputCss}
              />
              <label css={inputLabelCss} htmlFor="pgn">
                PGN
              </label>
              <input
                id="pgn"
                type="String"
                {...formik.getFieldProps("pgn")}
                css={inputCss}
              />
              <label css={inputLabelCss} htmlFor="link_to_game">
                Link To Game
              </label>
              <input
                id="link_to_game"
                type="String"
                {...formik.getFieldProps("link_to_game")}
                css={inputCss}
              />
            </div>
          </div>
          <label css={inputLabelCss} htmlFor="notes">
            Notes
          </label>
          <textarea
            id="notes"
            {...formik.getFieldProps("notes")}
            css={notesInputCss}
          />
          <div css={rowCSS}>
            <button
              css={signinButtonCss}
              type="submit"
              data-testid="signinSubmitButton"
            >
              Save New Game
            </button>
          </div>
          <div css={rowCSS}></div>
        </form>
      </div>
    </Fragment>
  );
};

const topContainerCSS = css`
  margin: 40px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
`;

const rowCSS = css`
  display: flex;
  margin: 5px;
  gap: 5px;
  flex-direction: row;
  align-items: flex-start;
`;

const signinButtonCss = css`
  //background-color: #e6e6e6;
  margin-top: 35px;
  font-family: Inter, monospace, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 14px;
  //color: ${palette.Color_Grey_8};
  border: 1px solid ${palette.Color_Primary_4};
  border-radius: 4px;
  width: 296px;
  height: 46px;
`;

const signinButtonTextCss = css`
  color: ${palette.Color_Primary_4};
  font-family: Inter, monospace, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
`;

const inputLabelCss = css`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  color: ${palette.Color_Primary_4};
  font-family: Inter, monospace, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  border: none;
`;
const inputCss = css`
  padding: 8px;
  min-width: 400px;
  color: black;
  font-family: Inter, monospace, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  border: none;
  border: 1px solid #a1a1a1;
`;
const notesInputCss = css`
  display: block;
  padding: 8px;
  min-width: 800px;
  height: 200px;
  color: black;
  font-family: Inter, monospace, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  border: none;
  border: 1px solid #a1a1a1;
`;
const formCss = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const checkBoxCss = css`
  float: left;
  color: ${palette.Color_Primary_4};
  font-family: Inter, monospace, Arial, Helvetica, sans-serif;
  font-weight: 500;
  font-size: 16px;
  border: none;
  margin-bottom: 5px;
`;

const logInHeadlineCss = css`
  margin-top: 40px;
  font-size: 22px;
  color: ${palette.Color_Primary_4};
  font-family: Inter, monospace, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  text-align: center;
`;
