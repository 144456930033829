/** @jsxImportSource @emotion/react */
import React from "react";
import dayjs from "dayjs";
import { UserInterface } from "../user/user";
import { css } from "@emotion/react";
import { GameEntryForm } from "./game_entry";
import { Game } from "../game/types/game";
import { DateTime } from "luxon";

export const GameView = (props: { user: UserInterface; path: string }) => {
  const buildGamesHTML = () => {
    const builtGames: any[] = [];
    if (props.user.games !== undefined) {
      if (props.user.games.length > 0) {
        props.user.games.forEach((nextGame: Game, index) => {
          builtGames.push(
            <div css={topLevelContainerCss} key={nextGame._id}>
              <button
                onClick={() =>
                  props.user.handleDeleteGame(props.user, nextGame)
                }
                css={deleteButtonCss}
              >
                X
              </button>
              <div>
                {dayjs(nextGame.date_played).format("MM/DD/YYYY")},{" "}
                {DateTime.fromISO(nextGame.time_played).toFormat("HH:mm")}
              </div>
              <div>{nextGame.time_control}</div>
              <div>{nextGame.color}</div>
              <div>
                {nextGame.result}/{nextGame.how_resolved}
              </div>
              <div>{nextGame.notes}</div>
            </div>
          );
        });
      }
    }
    return builtGames;
  };

  const gamesHTML = buildGamesHTML();
  return (
    <div>
      <div css={topLevelContainerCss} style={{ fontWeight: "bold" }}>
        <div></div>
        <div>Date and Time</div>
        <div>Controls</div>
        <div> Color</div>
        <div>Result</div>
        <div>Notes</div>
      </div>
      {gamesHTML}
    </div>
  );
};

const topLevelContainerCss = css`
  margin: 40px;
  display: grid;
  grid-template-columns: 30px 150px 100px 100px 100px 200px;
`;

const deleteButtonCss = css`
  width: 25px;
  text-align: left;
`;

const gameContainerCss = css`
  display: flex;
  flex-direction: row;
`;
