import axios from "axios";
import { User } from "../shared/user/user";
import { Game } from "./types/game";

export async function DeleteGameFromDB(
  game_id: string,
  user: User
): Promise<{ api_result: {} }> {
  const data = {
    type: "Delete",
    tableName: "game",
    app_id: user._id,
    where: { _id: game_id },
  };
  try {
    let theResponse = await axios.post("/sql", data);
    return theResponse.data;
  } catch (e) {
    console.log("Login error", JSON.stringify(e), e.response.data);
    return e;
  }
}
