import axios from "axios";

export async function SendPasswordResetRequest(
  emailOrUsername: string
): Promise<{ api_result: {} }> {
  const data = {
    emailOrUsername: emailOrUsername,
  };
  try {
    let theResponse = await axios.post("/auth/passwordResetRequest", data);
    return theResponse.data;
  } catch (e) {
    console.log("Login error", JSON.stringify(e), e.response.data);
    return e;
  }
}
