import axios from "axios";
import { User } from "../shared/user/user";

export async function SendSignin(
  emailOrUsername: string,
  password: string
): Promise<{ api_result: {}; user: User }> {
  const data = {
    type: "Login",
    emailOrUsername: emailOrUsername,
    password: password,
  };
  try {
    let theResponse = await axios.post("/auth/login", data);
    return theResponse.data;
  } catch (e) {
    console.log("Login error", JSON.stringify(e), e.response.data);
    return e;
  }
}
