export const api_return_values = {
  bad_password: "bad password",
  no_such_user: "user was not found",
  successful_logout: "user was logged out",
  successful_password_reset_request: "password reset email sent",
  failed_password_reset_request: "password reset email not sent",
  successful_password_reset: "password reset successfully",
  failed_password_reset: "password reset failed",
  password_reset_token_expired: "password reset token expired",
  user_not_logged_in: "user is not logged in",
  user_logged_in: "user is logged in",
  successful_signup: "user successfully signed up",
  failed_signup: "signup failed",
  signin_success: "User sucessfully signed in",
  signin_failure: "Sign in failed",
  general_sql_success: "Sql request succeeded",
};

export const api_return_details = {
  duplicate_email: "Email already registered",
  bad_username_email_password_combibination:
    "Bad username/email and password combination",
  reset_token_incorrect: "reset token does not match token sent in email",
  reset_toke_expired: "reset token has expired",
};
