import axios from "axios";
import { Game } from "./types/game";

export async function getGames(): Promise<{ games: Game[] }> {
  try {
    const games: Game[] = [];
    let theResponse = await axios.post("/getGames");
    console.log("get games", theResponse.data);
    return { games: theResponse.data.api_result_data };
  } catch (e) {
    console.log("Login here error", e);
    return e;
  }
}
