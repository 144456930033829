import axios from "axios";

export async function SendPasswordReset(
  emailOrUsername: string,
  password: string,
  reset_token: string
): Promise<{ api_result: {}; api_result_details: {} }> {
  const data = {
    emailOrUsername: emailOrUsername,
    password: password,
    reset_token: reset_token,
  };
  try {
    let theResponse = await axios.post("/auth/passwordReset", data);
    return theResponse.data;
  } catch (e) {
    console.log("Login error", JSON.stringify(e), e.response.data);
    return e;
  }
}
