import { navigate } from "@reach/router";
import { useState } from "react";
import { SendSignup } from "./send_signup";
import { SendLogout } from "./send_logout";
import { SendSignin } from "./send_signin";
import { SendPasswordResetRequest } from "./send_password_reset_request";
import { SendPasswordReset } from "./send_password_reset";
import { User } from "../shared/user/user";
import { api_return_values } from "../shared/api_return_values/api_return_values";
import { SendLogInCheck } from "./send_log_in_check";
import { getGames } from "../game/get_games";
import { Game } from "../game/types/game";
import { AddGameToDB } from "../game/add_game_to_DB";
import { DeleteGameFromDB } from "../game/delete_game_from_DB";
const _ = require("lodash");

export interface handleAddGameInterface {
  (passed_user: User, newGame: Game): Promise<void>;
}
export interface handleDeleteGameInterface {
  (passed_user: User, game: Game): Promise<void>;
}
interface handleSignupInterface {
  (email: string, username: string, password: string): Promise<void>;
}
interface handleLogoutInteface {
  (): void;
}
interface handleSigninInteface {
  (username: string, password: string): void;
}
interface checkIfLoggedInInterface {
  (): void;
}
interface handlePasswordResetRequestInterface {
  (username: string): void;
}
interface handlePasswordResetInterface {
  (username: string, password: string, resetToken: string): void;
}

export interface UserInterface extends User {
  handleAddGame: handleAddGameInterface;
  handleDeleteGame: handleDeleteGameInterface;
  handleSignup: handleSignupInterface;
  handleLogout: handleLogoutInteface;
  handleSignin: handleSigninInteface;
  checkIfLoggedIn: checkIfLoggedInInterface;
  handlePasswordResetRequest: handlePasswordResetRequestInterface;
  handlePasswordReset: handlePasswordResetInterface;
}

export function useUser() {
  const [user, setUser] = useState<UserInterface>({
    handleAddGame,
    handleDeleteGame,
    handleSignup,
    handleLogout,
    handleSignin,
    checkIfLoggedIn,
    handlePasswordResetRequest,
    handlePasswordReset,
    email: "",
    username: "",
    isLoggedIn: false,
    _id: "",
    emailOrUsername: "",
    apps: [],
    games: [],
    app_id: "",
    unsavedChanges: false,
    manualSave: false,
    defaultapp_id: "",
    password: "",
    role: "",
  });

  async function handleAddGame(passed_user: User, newGame: Game) {
    const newDBGame = await AddGameToDB(passed_user, newGame);
    console.log("new Game", newGame, newDBGame);

    passed_user.games.push(newDBGame.api_result_data[0]);
    passed_user.games = _.cloneDeep(passed_user.games);
    setUser((prevUser) => {
      return { ...prevUser, games: passed_user.games };
    });
  }

  async function handleDeleteGame(passed_user: User, game: Game) {
    const newDBGame = await DeleteGameFromDB(game._id, passed_user);
    const gameIndex = passed_user.games.findIndex(
      (nextGame) => nextGame._id === game._id
    );

    passed_user.games.splice(gameIndex, 1);

    passed_user.games = _.cloneDeep(passed_user.games);
    setUser((prevUser) => {
      return { ...prevUser, games: passed_user.games };
    });
  }

  async function handleSignup(
    email: string,
    username: string,
    password: string
  ) {
    let signupResult = await SendSignup(email, username, password);

    if (signupResult.api_result === api_return_values.successful_signup) {
      setUser({ ...user, email: email, username: username, isLoggedIn: true });
      navigate("/");
    } else {
      setUser({
        ...user,
        password: password,
        email: email,
        username: username,
        isLoggedIn: false,
      });
      navigate("/signup");
    }
  }

  async function handleSignin(username: string, password: string) {
    let signinResult = await SendSignin(username, password);
    if (signinResult.api_result === api_return_values.signin_success) {
      setUser({
        ...user,
        ...signinResult.user,
        emailOrUsername: signinResult.user.username,
        isLoggedIn: true,
      });
      refreshAllGames(user);
      navigate("/games");
    } else {
      setUser({ ...user, emailOrUsername: username, isLoggedIn: false });
      navigate("/signin");
      alert("Bad username/email and password combination");
    }
  }

  async function checkIfLoggedIn() {
    let logInCheckResult = await SendLogInCheck();
    if (logInCheckResult.isLoggedIn) {
      setUser({ ...user, ...logInCheckResult });
      refreshAllGames(user);
    } else {
      setUser({ ...user, isLoggedIn: false });
    }
  }

  async function handleLogout() {
    let logoutResult = await SendLogout();
    if (logoutResult.api_result === api_return_values.successful_logout) {
      setUser({ ...user, email: "", username: "", isLoggedIn: false });
      navigate("/signin");
    }
  }

  async function handlePasswordResetRequest(username: string) {
    let theResponse = undefined;
    try {
      theResponse = await SendPasswordResetRequest(username);
      if (
        theResponse.api_result ===
        api_return_values.successful_password_reset_request
      ) {
        alert("Password reset email sent");
      } else {
        alert("No account with provided email/username found");
      }
      navigate("/signin");
    } catch (e) {
      alert("Not sent:" + e.message);
      console.log("Request Password reset error:", e);
      return e;
    }
  }

  async function refreshAllGames(passed_user: User) {
    const result = await getGames();
    console.log("Result", result);
    setUser((prevUser) => {
      return {
        ...prevUser,
        games: result.games,
      };
    });
  }

  async function handlePasswordReset(
    emailOrUsername: string,
    password: string,
    resetToken: string
  ) {
    try {
      let theResponse = await SendPasswordReset(
        emailOrUsername,
        password,
        resetToken
      );
      if (
        theResponse.api_result === api_return_values.successful_password_reset
      ) {
        alert("Password reset");
        navigate("/signin");
      } else {
        alert("Password reset failed - " + theResponse.api_result_details);
        navigate("signin/");
      }
      navigate("/signin");
    } catch (e) {
      console.log("Request Password reset error:", e);
      return e;
    }
  }

  return user;
}
