import axios from "axios";

export async function SendLogInCheck(): Promise<{ isLoggedIn: boolean }> {
  const data = {};
  try {
    let theResponse = await axios.post("/auth/checkIfLoggedIn", data);
    return theResponse.data;
  } catch (e) {
    console.log("Login error", JSON.stringify(e), e.response.data);
    return e;
  }
}
