/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { AppNavBar } from "./navigation/app_nav_bar";
import { useUser } from "./user/user";
import { alternateBorders } from "./css/css";
import { Router } from "@reach/router";
import { SignupForm } from "./user/signup_form";
import { SigninForm } from "./user/sigin_form";
import React, { useEffect } from "react";
import { ResetPasswordForm } from "./user/reset_password_form";
import { RequestPasswordResetForm } from "./user/request_password_reset_form";
import { GameView } from "./game/game_view";
import { GameEntryForm } from "./game/game_entry";
import { useBeforeunload } from "react-beforeunload";
import GitInfo from "react-git-info/macro";

export default function App() {
  const user = useUser();

  useBeforeunload((event) => {
    if (user.unsavedChanges) {
      event.preventDefault();
    }
  });

  useEffect(() => {
    const checkLogIn = async () => {
      await user.checkIfLoggedIn();
    };
    checkLogIn();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const gitInfo = GitInfo();
  return (
    <div css={appContainerCss}>
      <div>{gitInfo.tags}</div>
      <AppNavBar user={user} />
      <Router>
        <GameView user={user} path="games" />
        <GameEntryForm user={user} path="game_entry" />
        <SignupForm user={user} path="signup" />
        <SigninForm user={user} path="signin" />
        <RequestPasswordResetForm user={user} path="requestpasswordreset" />
        <ResetPasswordForm
          user={user}
          resetToken={""}
          path="resetPassword/:resetToken"
        />
      </Router>
    </div>
  );
}
const appContainerCss = css`
  ${alternateBorders()}
`;
